import {
  Component,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  OnInit,
  OnDestroy
} from '@angular/core';
import { Subscription } from 'rxjs';
import { EventManagerService } from '../../../pages/general/blog/event-manager.service';
declare let $: any;
@Component({
  selector: 'dropdown',
  templateUrl: './dropdown-search.component.html',
  styleUrls: ['./dropdown-search.component.scss']
})
export class DropdownSearchComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() title: string = '';
  @Input() list: any;
  @Input() disabled: any;
  @Input() isHaveContent = false;
  @Output() result: EventEmitter<any> = new EventEmitter();
  @Output() resultGroup: EventEmitter<any> = new EventEmitter();
  @Input() value: any = null;
  @Input() field: string = 'name';
  @Input() key: string = 'id';
  @Input() name: any = '';
  @Input() multiple: any = false;
  @Input() backgroundColor = 'transparent';
  @Input() minWidth = '120px';
  @Input() minCharacter = 20;
  @Input() values: any[] = [];
  @Input() optionGroups: OptionGroup[] = [];
  defaultItem: any = { id: undefined };
  @Input() isNoAll: any = true;
  textSearch: string = '';
  valueShow: string = '';
  groups: any[] = [];
  openDropdownlist = false;
  eventSubscribers?: Subscription[] = [];
  id = 'myDropdown' + Math.floor(Math.random() * 1000) + Math.floor(Math.random() * 999);
  constructor(private eventManager: EventManagerService) {}
  ngOnInit() {
    this.eventSubscribers = [
      this.eventManager.subscribe('selected-item', (res: any) => {
        if (res.content) {
          this.chooseValue(res.content);
        }
      }),
      this.eventManager.subscribe('body-click', (res: any) => {
        const event = res.content.event;
        if (
          $(event.target).closest('#' + this.id).length === 0 &&
          $(event.target).closest('#action' + this.id).length === 0
        ) {
          this.openDropdownlist = false;
          const element: any = document.getElementById(this.id);
          element && element.classList.remove('show');
        } else {
          this.openDropdownlist = true;
        }
      })
    ];
  }
  ngAfterViewInit() {
    if (typeof window !== 'undefined') {
      $('select').click(function (e: any) {
        e.preventDefault();
      });
    }
    this.cutName();
  }
  ngOnDestroy(): void {
    this.eventManager.destroys(this.eventSubscribers || []);
  }
  ngOnChanges(simpleChanges: SimpleChanges) {
    const changes: any = simpleChanges;
    if (changes.list && changes.list.currentValue) {
      this.list = JSON.parse(JSON.stringify(changes.list.currentValue || []));
      if (this.value) {
        const find =
          this.list.find(
            (item: any) =>
              item[this.key] === this.value ||
              item[this.key] === this.value[this.key] ||
              item[this.field] === this.value[this.field]
          ) || {};
        this.name = find[this.field];
      } else {
        this.name = '';
      }
    }
    if (!this.value && this.value !== 0) {
      this.name = this.title;
    }
    if ((this.value || this.value === 0) && this.list && this.list.length > 0) {
      const find =
        this.list.find(
          (item: any) =>
            item[this.key] === this.value ||
            item[this.key] === this.value[this.key] ||
            item[this.field] === this.value[this.field]
        ) || {};
      this.name = find[this.field];
    }
    if (this.values.length > 0 && this.multiple && this.list && this.list.length > 0) {
      this.list.forEach((item: any) => {
        const isExist = this.values.find((value) => {
          return value[this.key] === item[this.key];
        });
        item.isCheck = isExist ? true : false;
      });
      const removeItems: any[] = [];
      this.values.forEach((value) => {
        const index = this.list.findIndex((item: any) => {
          return value[this.key] === item[this.key];
        });
        if (index === -1) {
          removeItems.push(index);
        }
      });
      removeItems.reverse();
      removeItems.forEach((index) => {
        this.values.splice(index, 1);
      });
      this.name = this.values.map((item) => item[this.field]).join(', ');
    }
    if (this.optionGroups.length > 0) {
      this.groups = [];
      this.optionGroups.forEach((option) => {
        const list = this.list.filter((item: any) => option.values.some((value) => value === item[option.field])) || [];
        this.groups.push({
          option,
          list
        });
      });
    }
    this.cutName();
  }
  cutName() {
    if (typeof window === 'undefined') {
      return;
    }
    const defaultWidth = !$('#action' + this.id)[0] ? 200 : $('#action' + this.id)[0].offsetWidth;
    const character = Math.floor(defaultWidth / (this.minCharacter * 7.5) - 0.1) * this.minCharacter; // 20 character = 150px
    this.name = this.name || '';
    this.valueShow =
      this.name.length >= character && character > 0 ? this.name.substring(0, character - 3) + '...' : this.name;
  }
  open() {
    const element: any = document.getElementById(this.id);
    element && element.classList.toggle('show');
    // document.getElementById('#backdrop').classList.toggle("show");
    $('#backdrop').toggleClass('show-backdrop');
    $('#backdrop').click(function (e: any) {
      $('#backdrop').removeClass('show-backdrop');
    });
  }
  filter() {
    const filter = this.textSearch.toUpperCase();
    if (!this.groups.length) {
      this.list.forEach((item: any) => {
        const text = (item[this.field] || '').toUpperCase();
        if (text.includes(filter) || !filter) {
          item.isHide = false;
        } else {
          item.isHide = true;
        }
      });
    } else {
      this.groups.forEach((group) => {
        group.list.forEach((item: any) => {
          const text = (item[this.field] || '').toUpperCase();
          if (text.includes(filter) || !filter) {
            item.isHide = false;
          } else {
            item.isHide = true;
          }
        });
      });
    }
  }
  chooseValue(value: any) {
    if (!this.multiple) {
      const element: any = document.getElementById(this.id);
      element && element.classList.remove('show');
      this.value = value || {};
      this.name = this.value[this.field];
      this.result.emit(value);
    } else {
      const firstValue = this.values[0];
      if (firstValue && !firstValue.id) {
        this.values = this.list.filter((item: any) => item.isCheck);
      }
      const index = this.values.findIndex((item) => item[this.key] === value[this.key]);
      if (index === -1) {
        value.isCheck = true;
        if (!this.values.length && !value[this.key]) {
          this.values = [value];
          this.list.forEach((item: any) => (item.isCheck = true));
        } else if (this.values.length && !value[this.key]) {
          this.values = [];
          this.list.forEach((item: any) => (item.isCheck = false));
        } else if (value[this.key]) {
          this.values.push(value);
        }
      } else {
        value.isCheck = false;
        this.values.splice(index, 1);
      }
      this.name = this.values.map((item) => item[this.field]).join(', ');
      this.result.emit(this.values);
    }
    this.cutName();
    $('#backdrop').removeClass('show-backdrop');
  }
  chooseGroup(group: any) {
    this.resultGroup.emit(group);
  }
}
export class OptionGroup {
  field: string = '';
  values: any[] = [];
  title: string = '';
  constructor(params?: any) {
    if (!params) {
      return;
    }
    this.field = params.field;
    this.values = params.values;
    this.title = params.title;
  }
}

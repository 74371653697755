import { Injectable } from '@angular/core';

/**
 * declare Jquery
 * */
declare let $: any;

@Injectable({ providedIn: 'any' })
export class MenuService {
  public isShow = false;
  public list: any = [];
  toglle() {
    $('#navbarNavDropdown').collapse('toggle');
    $('.navbar-header .navbar-toggle').toggleClass('active');
  }

  toglleDashboard() {
    $('#navbar').collapse('toggle');
    $('.navbar-header .navbar-toggle').toggleClass('active');
  }

  show() {
    this.isShow = true;
    $('#navbarNavDropdown').collapse('show');
    $('.navbar-header .navbar-toggle').removeClass('active');
  }
  hide() {
    this.isShow = false;
    $('#navbarNavDropdown').collapse('hide');
    $('.navbar-investor,.navbar-issuer').removeClass('active');
    $('.navbar-header .navbar-toggle').removeClass('active');

    // $('body').removeClass("modal-open");
  }

  hideDashboard(id: string) {
    this.isShow = false;
    // $('#navbar').collapse('hide');
    $(id).addClass('dropdown-menu-off');
    setTimeout(() => {
      $(id).removeClass('dropdown-menu-off');
    }, 200);
    $('#header, .navbar-header .navbar-toggle').removeClass('active');
    $('#navbar').removeClass('show');

    // $('body').removeClass("modal-open");
  }

  setMenuList(list: any) {
    this.list = list.map((item: any) => {
      return {
        name: item,
        isShow: false
      };
    });
  }
  activeMenu(name: any) {
    this.list = this.list.map((item: any) => {
      if (item.name === name) {
        item.isShow = true;
      } else {
        item.isShow = false;
      }
      return item;
    });
  }
  getStatusMenu(name: any) {
    let result = false;
    this.list.forEach((item: any) => {
      if (item.name === name) {
        result = item.isShow;
      }
    });
    return result;
  }
}

import { CopyProperties } from './copy-properties.decorator';

@CopyProperties()
export class QueryParams  {
  public page: number = 1;
  public previousPage: number;
  public ascending: string;
  public predicate: string;
  public totalItems: number = 0;
  public size: number  = 10;
  public searchParams: any;
  public sort: any;
  constructor(params?: any) {
    this.page = 1;
    this.previousPage = 1;
    this.totalItems = 0;
    this.size = 25;
    this.ascending = '';
    this.predicate = '';
    this.searchParams  = {};
    this.sort  = {key: 'id', value: 'desc'}; // set default sort by id
  }
}

import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, Injector, Injectable } from '@angular/core';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { QueryParams } from '../pages/general/blog/models/query.params.model';
import { EventManagerService } from '../pages/general/blog/event-manager.service';

@Injectable({ providedIn: 'any' })
export abstract class ManageComponent implements OnInit, OnDestroy {
  currentAccount: any | null = null;
  subscriptions: Subscription[] = [];
  subsCommon: Subscription[] = [];
  list?: any[] = [];
  extraData: any = {};
  params: any = {};
  routeParams: any;
  queryParams: QueryParams = new QueryParams();
  searchParams: any;
  selectedItem: any;
  isCall: boolean = false;
  isExecuting: boolean = false;
  isExporting: boolean = false;
  isUpload: boolean = false;
  callApi$ = new Subject();
  callExport$ = new Subject();
  path: string = '';
  bodyInput: any = {};
  constructor(
    public eventManager: EventManagerService,
  ) {

  }
  ngOnInit() {
    this.subsCommon = [...[
      this.eventManager.subscribe('load-page', (res: any) => {
        this.loadPage((res.content || {}).page || this.queryParams.page);
      }),
    ]];
    this.defaultParams();
    this.callApi$.pipe(
      debounceTime(1000),
      ).subscribe((event)=> { return this.callAPI();});
    this.callExport$.pipe(
      debounceTime(1000),
      ).subscribe((event)=> { return this.callExport(event);});
  }
  parseQueryParams(queryParams: any) {
    Object.keys(queryParams).forEach((key) => {
      if (key === 'page' || key === 'size') {
        this.queryParams[key] = queryParams[key] ? parseInt(queryParams[key]) : this.queryParams[key]
      } else {
        this.queryParams.searchParams[key] = queryParams[key];
      }
    });
    this.searchParams = Object.assign({}, this.queryParams.searchParams);
  }
  callAPI() {
    this.loadAll();
  }
  executeCallAPI(event?: any) {
    this.callApi$.next(event || true);
  }
  export() {
    this.executeCallExport();
  }
  callExport(event?: any) {
  }
  executeCallExport(event?: any) {
    this.callExport$.next(event || true);
  }
  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.eventManager.destroys(this.subscriptions);
    }
    this.eventManager.destroys(this.subsCommon);
  }
  defaultParams() {
    this.queryParams = new QueryParams();
    this.searchParams = Object.assign({}, this.queryParams.searchParams);
  }
  checkChangeSearchParams() {
    const  isChange = Object.keys(this.queryParams.searchParams).some((properties) => {
      return this.queryParams.searchParams[properties] !== this.searchParams[properties];
    })
    if (isChange) {
      this.queryParams.page = 1;
      this.searchParams = Object.assign({}, this.queryParams.searchParams);
    }
    return this.queryParams;
  }
  trackId(index: number, item: any): number {
    return item.id;
  }
  trackIdentity(index: number, item: any) {
    return item.id;
  }
  loadPage(event: number) {
    const page = event;
    this.queryParams.page = page;
    if (page !== this.queryParams.previousPage) {
      this.queryParams.previousPage = page;
    }
    this.loadAll();
  }
  changePage(page: any) {
    this.queryParams.page = page;
    this.loadAll();
  }
  changeSize(size: any) {
    this.queryParams.page = 1;
    this.queryParams.size = size;
    this.loadAll();
  }
  checkDifferentBody(body: any) {
    body = body || {};
    return Object.keys(body).some((property) => {
      return body[property] !== this.bodyInput[property];
    });
  }
  loadAll(req?: any) {}
  sort(key: any, value: any) {
    this.searchParams.sort = {
      key,
      value
    }
    this.executeCallAPI();
  }
  search(event: any) {
    this.eventManager.broadcast({
      name: 'get-value-search',
      content: {}
    });
  }
  getValueSearch(event: any) {
    this.queryParams.searchParams = event;
    const keys = Object.keys(this.queryParams.searchParams);
    keys.forEach((key) => {
      if (typeof this.queryParams.searchParams[key] === 'string') {
        this.queryParams.searchParams[key] = this.queryParams.searchParams[key].trim();
        if (this.queryParams.searchParams[key] === 'undefined' || this.queryParams.searchParams[key] === '') {
          this.queryParams.searchParams[key] = undefined;
        }
      }
    });
    this.loadPage(1);
  }
  eventSearchParams(key: any, value: any) {
    this.eventManager.broadcast({
      name: 'add-option-search',
      content: {
        key, value
      }
    });
  }
  delete() {
  }
}

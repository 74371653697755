import {
  Component,
  OnInit,
} from '@angular/core';
import { Constant } from '../../../pages/general/blog/constant';

@Component({
  standalone: true,
  selector: 'fmarket-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['footer.scss'],
})
export class FooterComponent implements OnInit {
  theme: any = {};
  language: string = 'VI';
  languageText: string = 'Tiếng Việt';
  Constant = Constant;
  constructor() {}
  ngOnInit() {}
}

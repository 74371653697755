<div class="paging" *ngIf="totalPage > 1">
  <div class="prev" [ngClass]="selectedPage === 1 ? 'disabled' : ''" (click)="previous()">
    <span>
      <img
        src="https://files.fmarket.vn/blog/assets/params/images/blog/ic-prev.svg"
        loading="lazy"
        alt="prev button"
        width="11"
        height="11"
      />
      <label class="isDesktop">Trở lại</label>
    </span>
  </div>

  <ul>
    <li *ngFor="let item of pages; index as index">
      <span class="paging__item" (click)="changePage(item)" [ngClass]="item === selectedPage ? 'active' : ''">
        {{ item }}</span
      >
    </li>
  </ul>

  <div class="next" [ngClass]="selectedPage === totalPage ? 'disabled' : ''" (click)="next()">
    <span>
      <label class="isDesktop">Kế tiếp</label>
      <img
        src="https://files.fmarket.vn/blog/assets/params/images/blog/ic-next.svg"
        loading="lazy"
        alt="next button"
        width="11"
        height="11"
      />
    </span>
  </div>
</div>

import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  EventEmitter,
  Output,
  ViewEncapsulation,
  OnDestroy,
  Input
} from '@angular/core';
import { ManageComponent } from '../../../../extend-code/manage.component';
import { Constant } from '../../blog/constant';
import { EventManagerService } from '../../blog/event-manager.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from '../dashboard.service';

/**
 * declare Jquery
 * */
declare let $: any;

@Component({
  selector: 'table-products',
  templateUrl: './table-products.html',
  styleUrls: ['./table-products-1.scss', './table-products-2.scss', './table-products-responsive.scss']
})
export class TableProducts extends ManageComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() isViewSummary: boolean = false;
  @Input() isProductPage: boolean = false;
  @Input() isMarketPage: boolean = false;
  @Input() isFToolPage: boolean = false;
  @Input() isRewardPage: boolean = false;
  @Input() isHomePage: boolean = false;
  @Input() isBankInterestRatePage: boolean = false;
  @Input() isPerformanceFundPage: boolean = false;
  @Input() isGenerateImageUpload: boolean = false;
  @Input() userRewardValue: boolean = false;
  @Output() result: EventEmitter<any> = new EventEmitter();
  public Constant = Constant;
  @Input() selectedAssetType: any = 'ALL';
  @Input() products: any[] = [];
  issuers: any[] = [];
  fundAssetTypes: any[] = [];
  user: any; // UserProfile = new UserProfile();
  theme: any = {};
  isDevice: any = false;
  isTablet: boolean = false;

  navMonths = [
    { name: `Lợi nhuận 1 tháng`, value: 1, field: 'navTo1Months' },
    { name: `Lợi nhuận 3 tháng`, value: 3, field: 'navTo3Months' },
    { name: `Lợi nhuận 6 tháng`, value: 6, field: 'navTo6Months' },
    { name: `Lợi nhuận 1 năm`, value: 12, field: 'navTo12Months' },
    { name: `Lợi nhuận 2 năm`, value: 24, field: 'navTo24Months' },
    { name: `Lợi nhuận 3 năm`, value: 36, field: 'navTo36Months' }
  ];
  columnMonth = this.navMonths[3];
  constructor(
    private dashboardService: DashboardService,
    private router: Router,
    override eventManager: EventManagerService,
    public route: ActivatedRoute
  ) {
    super(eventManager);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    if (this.isFToolPage || this.isHomePage) {
      this.columnMonth = this.navMonths[3];
    }
    this.queryParams.size = this.isViewSummary ? 7 : 100;
    if (this.isGenerateImageUpload) {
      this.queryParams.size = 6;
    }
    if (!this.isPerformanceFundPage && !this.isBankInterestRatePage) {
      this.sort('navTo12Months', 'DESC');
    }
    this.subscriptions = [
      this.eventManager.subscribe('sort-table-products', (res: any) => {
        const content = res.content || {};
        this.sort(content.key, content.value);
      }),
    ];
    if (typeof window !== 'undefined') {
      this.isDevice = Constant.checkAndroid() || Constant.checkIOS();
      $(document).on('click', '.tab-scrollX-mobile .nav-link', function (e: any) {
        let tar = $(e.target).parents('li');
        let tarParent = tar.parents('ul');
        let x = tar.parents('body');
        tarParent.animate({
          scrollLeft: tar.offset().left + tarParent.scrollLeft() - tarParent.width() / 2
        });
      });
    }
    this.searchParams.issuerIds = [];
  }
  ngAfterViewInit() {
    this.getIssuers();
  }
  getIssuers() {
    return this.dashboardService.getIssuers({}).subscribe((res: any) => {
      this.issuers = (res.data.rows || [])
        .filter((item: any) => item.issuerType === 'FUND_ISSUER')
        .map((item: any) => {
          return { id: item.id, shortName: item.shortName };
        });
    });
  }
  override sort(key: any, value: any, isForce?: any) {
    this.searchParams.sort = {
      key,
      value
    };
    if (isForce) {
      this.columnMonth = this.navMonths[2];
    }
    this.executeCallAPI();
  }
  changeFundAsset(code: any) {
    this.selectedAssetType = code;
    this.queryParams.searchParams.isIpo = false;
    this.queryParams.searchParams.isMMFFund = false;
    this.queryParams.page = 1;
    this.loadAll();
  }
  filterIPOFund() {
    this.selectedAssetType = 'IPO';
    this.queryParams.searchParams.isIpo = true;
    this.queryParams.searchParams.isMMFFund = false;
    this.loadAll();
  }
  filterMMFund() {
    this.selectedAssetType = 'MMF';
    this.queryParams.searchParams.isIpo = false;
    this.queryParams.searchParams.isMMFFund = true;
    this.loadAll();
  }
  override loadAll() {
    let fundAssetTypes = null;
    if (this.isFToolPage) {
      return;
    }
    if (this.isBankInterestRatePage) {
      fundAssetTypes = ['BOND'];
      this.columnMonth = this.navMonths[3];
    }
    if (this.isPerformanceFundPage) {
      fundAssetTypes = ['STOCK'];
      this.columnMonth = this.navMonths[3];
    }
    this.searchParams.sort = this.searchParams.sort || {};
    let requestBody: any = {
      types: ['NEW_FUND', 'TRADING_FUND'],
      issuerIds: this.searchParams.issuerIds.map((item: any) => item.id),
      sortOrder: this.searchParams.sort.value,
      sortField: this.searchParams.sort.key,
      page: this.queryParams.page,
      pageSize: this.queryParams.size,
      isIpo: this.queryParams.searchParams.isIpo ? true : false,
      fundAssetTypes: fundAssetTypes
        ? fundAssetTypes
        : this.queryParams.searchParams.isIpo || this.queryParams.searchParams.isMMFFund
        ? undefined
        : this.selectedAssetType
        ? this.selectedAssetType !== 'ALL'
          ? [this.selectedAssetType]
          : []
        : [],
      bondRemainPeriods: [],
      subdomainOwnerIds: this.theme.subDomainOwnerId ? [this.theme.subDomainOwnerId] : undefined,
      searchField: this.queryParams.searchParams.text || '',
      isBuyByReward: this.isRewardPage ? true : false,
      userRewardValue: this.isRewardPage ? this.userRewardValue : undefined,
      isMMFFund: this.queryParams.searchParams.isMMFFund ? true : undefined
    };
    this.isExecuting = true;
    return this.dashboardService.getProducts(requestBody).subscribe(
      (res: any) => {
        this.isCall = true;
        this.isExecuting = false;
        if (!res.data) {
          return;
        }
        const result = res.data;
        this.queryParams.totalItems = res.data.total;
        result.rows = (result.rows || []).map((item: any) => {
          if (item.avgAnnualReturn >= 0) {
            item.up = true;
            item.avgAnnualReturn = '+' + item.avgAnnualReturn;
          } else {
            item.up = false;
          }
          item.avgAnnualReturn += '';
          item.avgAnnualReturn = item.avgAnnualReturn.substring(1, item.avgAnnualReturn.length);
          item.isNewProduct = !item.isProductIpo && (Date.now() - item.approveAt) / (24 * 60 * 60 * 1000) < 30;

          return {
            code: item.code,
            ownerAvatarUrl: (item.owner || {}).avatarUrl,
            ownerShortName: (item.owner || {}).shortName,
            shortName: item.shortName,
            assetTypeName: (item.dataFundAssetType || {}).name,
            extra: {
              currentNAV: (item.extra || {}).currentNAV,
              lastNAVDate: (item.extra || {}).lastNAVDate
            },
            productNavChange: item.productNavChange,
            ipoStartTime: (item.productFund || {}).ipoStartTime,
            ipoEndTime: (item.productFund || {}).ipoEndTime,
            isNewProduct: item.isNewProduct,
            isProductIpo: item.isProductIpo,
            linkBuy: Constant.url_login + `?productId=${item.id}`
          };
        });
        this.products = result.rows;
      },
      (err: any) => {
        this.isExecuting = false;
        this.isCall = true;
      }
    );
  }
  showIssuer(product: any) {
    this.eventManager.broadcast({
      name: 'detail-issuer',
      content: {
        id: product.id
      }
    });
  }
  chooseProduct(product: any) {
    if (this.isBankInterestRatePage || this.isPerformanceFundPage || this.isHomePage) {
      this.eventManager.broadcast({
        name: 'product-popup',
        content: product
      })
      return;
    }
    this.router.navigate([Constant.url_detail_fund + '/' + product.shortName]);
  }
  buy(product: any) {
    if (typeof window !== 'undefined') {
      window.open(Constant.url_login + `?productId=${product.id}`, '_self');
    }
  }
  checkEsign(product: any, properties?: any) {
    this.eventManager.broadcast({
      name: 'check-e-sign',
      content: {
        product,
        properties
      }
    });
  }
}

<div class="d-flex flex-column">
  <div class="d-flex" (click)="goElement(catalogueItem.idHTML)">
    <span class="mr-2 stt">{{index}}</span> 
    <p [innerHTML]="contentTrust"></p>
  </div>
  <ul class="ml-3">
    <li *ngFor="let item of catalogueItem.childrens; index as index1">
      <catalogue [catalogueItem]="item" [index]="index + ' ' + (index1 + 1) + '.'"></catalogue>
    </li>
  </ul>
</div>
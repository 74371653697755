import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Constant } from '../../../pages/general/blog/constant';
import { ViewportScroller } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { EventManagerService } from '../../../pages/general/blog/event-manager.service';

declare let $: any;

@Component({
  selector: 'catalogue',
  templateUrl: './catalogue.component.html',
  styleUrls: ['catalogue.scss'],
})
export class CatalogueComponent implements OnChanges {
  @Input() catalogueItem: any = {};
  @Input() content: string = '';
  @Input() index: any;
  Constant = Constant;
  contentTrust: any;
  constructor(
    public eventManager: EventManagerService,
    private sanitizer: DomSanitizer,
    private scroller: ViewportScroller,
  ) {}
  ngOnChanges(simpleChanges: SimpleChanges) {
    const changes: any = simpleChanges;
    this.contentTrust = this.sanitizer.bypassSecurityTrustHtml(this.catalogueItem.content);
  }
  goElement(id: string) {
    this.eventManager.broadcast({
      name: 'view-more-blog-detail',
      content: {}
    });
    setTimeout(() => {
      this.scroller.scrollToAnchor(id);
      const header = $(`#header`);
      header.addClass('header-hide-scroll');
      setTimeout(() => {
        header.removeClass('header-hide-scroll');
      }, 3 * 1000);
    }, 300)
  }
  
  revertSanitizer() {
    return this.sanitizer.bypassSecurityTrustHtml(this.catalogueItem.content);
  }
}

export class NumberUtil {
  static decimalAdjust(value: any, exp: any, type?: any) {
    type = type || 'round';
    if (exp < 0) {
      exp = exp * -1;
    }
    let valueMul = 1;
    for (let i=1; i <= exp; i++) {
      valueMul= valueMul * 10;
    }
    let math: any = Math;
    return math[type](value * valueMul) / valueMul;
  }
  static revertMoneytoNumber(number: any, returnNull?: any) {
    number && (number+='');
    if (number && number.length > 0) {
      let array =number.split(',');
      number='';
      array.forEach((item: any) => {
        number+=item
      })
      return parseFloat(number)
    } else if (number !== 0) {
      return !returnNull ? 0 : null;
    } else {
      return 0
    }
  }
  static numberToMoney(number: any) {
    let isNegative: boolean = false;
    let isDemacial: boolean = false;
    if (number < 0) {
      isNegative = true;
      number = number*-1;
    }
    let numberText = number +'';
    const splitDemacial = numberText.split('.');
    let numberPositive = splitDemacial[0] || '';
    let numberDecimal = splitDemacial[1] || '';
    if (splitDemacial.length === 2) {
      isDemacial = true;
    }
    let text = '';
    let position = 0;
    for (let i=numberPositive.length -1; i >= 0; i--) {
      if (position === 3) {
        text = ',' + text; 
        position = 0;
      }
      text = numberPositive.charAt(i) + text;
      position++;
    }
    if (isNegative) {
      text = '-' + text;
    }
    if (isDemacial) {
      text += '.' + numberDecimal;
    }
    return text;
  }
}
